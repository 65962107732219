import React from "react";
import "./App.css";
import About from "./components/About";
import Careers from "./components/Careers";
import Clients from "./components/Clients";
import Footer from "./components/Footer";
import Maincomponents from "./components/Maincomponent";
import Services from "./components/Services";

import Home from "./components/Pages/Home";
import Project from "./components/Pages/Project";
import Navbar from "./components/Navbar";

import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { useEffect } from "react";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />

        <Routes>
          <Route path="/" element={<Project />} />
          <Route path="/project/:name" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
