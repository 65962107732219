import React from "react";
import { useEffect } from "react";

export const useProject = (name) => {
  const [project, setProject] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState();

  const {
    data,
    loading,
    error: apiError,
  } = useServerApi(`https://git-api-server.vercel.app/Code-Decoders/${name}`, {
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  useEffect(() => {
    setIsLoading(loading);
    setError(apiError);
    if (data) {
      setProject(data);
    }
  }, [data, loading]);

  return [project, isLoading, error];
};

export const useAllProjects = () => {
  const [projects, setProjects] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(undefined);

  const {
    data,
    loading,
    error: apiError,
  } = useServerApi("https://git-api-server.vercel.app/", {
    method: "GET",
    header: {
      "Content-Type": "application/json",
    },
  });

  useEffect(() => {
    setIsLoading(loading);
    setError(apiError);

    if (data) {
      setProjects(data);
    }
  }, [data, loading]);

  return [projects, isLoading, error];
};

const useServerApi = (url, options = {}) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          headers: options.header || {},
          mode: options.mode || "cors",
          method: options.method || "",
          body: options.body ? JSON.stringify(options.body) : undefined,
        });
        if (!response.ok) {
          throw new Error(`HTTP ERROR! Status code ${response.status}`);
        }
        const responseData = await response.json();
        setData(responseData);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, loading, error };
};
