import React from "react";
import About from "../About";
import Careers from "../Careers";
import Clients from "../Clients";
import Footer from "../Footer";
import Maincomponents from "../Maincomponent";
import Navbar from "../Navbar";
import Services from "../Services";
import Social from "../social/Social";
import "./project.css";

const Project = () => {
  return (
    <div>
      <Navbar />
      <div className="content">
        <Maincomponents />
        <Clients />
        <div className="sticky-container">
          <Services />
          <About />
        </div>
        <Careers />
        <Social />
      </div>  
      <Footer />
    </div>
  );
};

export default Project;
