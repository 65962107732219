import React from "react";
import "./navBar.css";
import svg0 from "../../img/logo.png.svg";
import svg from "../../img/arrow.png1.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import arrow from "../../img/arrow.png1.svg";

const Navbar = () => {
  return (
    <motion.div
      className="navBar"
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: 1, opacity: 0 }}
      transition={{ delay: 0, duration: 0.77 }}
    >
      <Link to="/">
        <div className="navBar-logo">
          <img style={{ width: "100%" }} src={svg0} alt="logo" />
        </div>
      </Link>

      <a className="email-logo" href="mailto:info@codedecoders.io" target="_blank">
        <p>info@codedecoders.io</p>

        <img
          className="email-arrow"
          src={svg}
          alt="logo"
          style={{ width: "22px", height: "22px" }}
        />
      </a>
    </motion.div>
  );
};

export default Navbar;

// import React from 'react'
// import './navBar.css';
// import svg0 from '../../img/logo.png.svg';
// import svg from '../../img/arrow.png1.svg';
// import { motion } from "framer-motion";

// const Navbar = () => {

//   return (
//     <motion.div className='navBar'
//     animate={{ y: 0, opacity: 1}}
//     initial={{ y: 0, opacity :0}}
//     transition={{ delay: 0, duration: 0.77}}>
//          <div className='navBar-logo'>
//         <img className='logo' src={svg0} alt="logo" />

//       </div>

//       <div className='email-logo'>
//         <span>agency@email.com</span>
//         <img className='logo1' src={svg} alt="logo" />
//       </div>

//     </motion.div>

//   )

// }

// export default Navbar;
