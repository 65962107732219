import React from "react";
import "./home.css";
import svg00 from "../../img/logo.png.svg";
import svg20 from "../../img/logo.png.svg";
import video from "../video/project.video.gif";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Social from "../social/Social";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { motion } from "framer-motion";
import { useProject } from "../../hooks";
import { formatTitle } from "../../utils/formatTitle";
import Clients from "../Clients";

const Home = () => {
  const { pathname } = useLocation();
  const [project, isLoading, error] = useProject(pathname.split("/")[2]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    console.log(project);
  }, []);

  if (isLoading) return <div></div>;
  return (
    <>
      <Navbar />
      <div className="project">
        <motion.div
          className="project-container"
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: "30%", opacity: 0 }}
          transition={{ delay: 0.0, duration: 0.75 }}
        >
          <h1 className="project-title">{formatTitle(project.name)}</h1>
          <div className="project-header-text">
            <div className="project-text-header">
              {project.category.slice(0, 3).map((category, index) => (
                <>
                  <span className="project-text-title">
                    {formatTitle(category)}
                  </span>
                  {index !== project.category.slice(0, 3).length - 1 && (
                    <img
                      className="project-text-logo-1"
                      src={svg00}
                      alt="Mplogo"
                    />
                  )}
                </>
              ))}
            </div>

            <p className="project-text">{project.description}</p>
          </div>
        </motion.div>
        <motion.div
          className="home-img"
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: "30%", opacity: 0 }}
          transition={{ delay: 0.1, duration: 0.75 }}
        >
          <div class="video-container">
            <iframe
              className="responsive-iframe"
              src={`https://www.youtube.com/embed/${project.youtubeLink.slice(
                17
              )}?autoplay=1&mute=1&controls=0`}
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </motion.div>

        <div className="project-2-container">
          <div className="project-sticky-container">
            <motion.h1
              className="project-sticky-title"
              whileInView={{ y: 0, opacity: 1 }}
              initial={{ y: "30%", opacity: 0 }}
              transition={{ delay: 0.2, duration: 0.75 }}
            >
              Inspiration
            </motion.h1>
            {project.inspiration.split("\n\n").map((paragraph, index) => (
              <motion.p
                className="project-sticky-text-1"
                whileInView={{ y: 0, opacity: 1 }}
                initial={{ y: "30%", opacity: 0 }}
                transition={{ delay: 0.3, duration: 0.75 }}
              >
                {paragraph}
              </motion.p>
            ))}
          </div>
          <div className="home-container-imgs">
            {project.screenshots.map((screenshot, index) => (
              <motion.img
                className="home-img1"
                src={screenshot}
                alt=""
                whileInView={{ y: 0, opacity: 1 }}
                initial={{ y: "30%", opacity: 0 }}
                transition={{ delay: 0.5, duration: 0.75 }}
              />
            ))}
          </div>
        </div>

        <div className="CEO-container ">
          <motion.div
            className="CEO-title"
            whileInView={{ y: 0, opacity: 1 }}
            initial={{ y: "30%", opacity: 0 }}
            transition={{ delay: 0.2, duration: 0.75 }}
          >
            <p>
              “One of the most unique and interesting projects I have every
              seen. Looking forward to see where the team takes this in the
              future.”
            </p>

            <div className="CEO-name ">
              <img
                className="photo"
                src="https://avatars3.githubusercontent.com/u/4899480?height=180&v=4&width=180"
                alt="img"
              />
              <div className="profile">
                <h3 className="CEO-Name">Eric Scentia </h3>

                <h3 className="Ceo-text"> CEO of Scentia</h3>
              </div>
            </div>
          </motion.div>
        </div>
        <div className="container-home1">
          <motion.h3
            className="container-1"
            whileInView={{ y: 0, opacity: 1 }}
            initial={{ y: "30%", opacity: 0 }}
            transition={{ delay: 0.2, duration: 0.75 }}
          >
            Features
          </motion.h3>
          <motion.div
            className="container-home2"
            whileInView={{ y: 0, opacity: 1 }}
            initial={{ y: "30%", opacity: 0 }}
            transition={{ delay: 0.2, duration: 0.75 }}
          >
            <h3 className="container-2">
              Some features of {formatTitle(project.name)} are:
            </h3>
            <h3 className="container-3">
              {project.features
                .split("- ")
                .slice(1)
                .map((feature, index) => (
                  <>
                    <span className="container-4">
                      {index + 1}
                      {". "}
                      {feature}
                    </span>
                    <br />
                  </>
                ))}
            </h3>
          </motion.div>
        </div>
        <div className="home-clients">

        <Clients />
        </div>

        <div className="Home-social">
          <Social />
        </div>

        <div className="home-Footer">
          <Footer />
        </div>
      </div>
    </>
  );
};
export default Home;
