import React from "react";
import "./services.css";
import svg1 from "../../img/logo.png.svg";
import svg2 from "../../img/logo.png.svg";
import svg3 from "../../img/logo.png.svg";
import svg4 from "../../img/logo.png.svg";
import { motion } from "framer-motion";

const Services = () => {
  return (
    <div className="services">
      <motion.h1
        className="services-title"
        whileInView={{ y: 0, opacity: 1 }}
        initial={{ y: "100%", opacity: 0 }}
        transition={{ duration: 0.75 }}
      >
        Services
      </motion.h1>
      <div className="services-text-container">
        <motion.div
          className="services-container"
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: "100%", opacity: 0 }}
          transition={{ duration: 0.75 }}
        >
          <img className="services-logo" src={svg1} alt="sr-logo1" />

          <h3 className="services-text-title">App Development</h3>
          <p className="services-text">
            We design and develop custom mobile applications for iOS and Android
            platforms that meet the specific needs of your business or
            organization.
          </p>
        </motion.div>

        <motion.div
          className="services-container"
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: "100%", opacity: 0 }}
          transition={{ duration: 0.75 }}
        >
          <img className="services-logo" src={svg2} alt="sr-logo2" />

          <h3 className="services-text-title">Web Development</h3>
          <h3 className="services-text">
            We create responsive and visually appealing websites that are
            tailored to your brand, optimized for search engines, and built with
            the latest web technologies.
          </h3>
        </motion.div>

        <motion.div
          className="services-container"
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: "100%", opacity: 0 }}
          transition={{ duration: 0.75 }}
        >
          <img className="services-logo" src={svg3} alt="sr-logo3" />

          <h3 className="services-text-title">Blockchain Development</h3>
          <h3 className="services-text">
            We leverage blockchain technology to build decentralized
            applications (dApps), smart contracts, and other solutions that
            enable secure and transparent transactions.
          </h3>
        </motion.div>

        <motion.div
          className="services-container"
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: "100%", opacity: 0 }}
          transition={{ duration: 0.75 }}
        >
          <img className="services-logo" src={svg4} alt="sr-logo4" />

          <h3 className="services-text-title">Game Development</h3>
          <h3 className="services-text">
            We design and develop engaging and immersive games for various
            platforms, including mobile, PC, and console, that offer a seamless
            and enjoyable user experience.
          </h3>
        </motion.div>
      </div>
    </div>
  );
};

export default Services;
