import React from "react";
import svg from "../../img/arrow.png1.svg";
import "./social.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Social = () => {
  return (
    <div className="social">
      <a
        className="social-email"
        href="mailto:info@codedecoders.io"
        target="_blank"
      >
        <h1 className="email">info@codedecoders.io</h1>
        <img className="social-email-logo" src={svg} alt="logos11" />
      </a>

      <div className="social-links">
        <motion.div
          className="social-link"
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: "30%", opacity: 0 }}
          transition={{ delay: 0.1, duration: 0.75 }}
        >
          <Link
            to="https://twitter.com/CodeDecoders"
            target={"_blank"}
            className="social-link"
          >
            Twitter
          </Link>
        </motion.div>
        <motion.div
          className="social-link"
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: "30%", opacity: 0 }}
          transition={{ delay: 0.25, duration: 0.75 }}
        >
          <Link
            to="https://www.linkedin.com/company/codedecoders/"
            target={"_blank"}
            className="social-link"
          >
            LinkedIn
          </Link>
        </motion.div>
        <motion.div
          className="social-link"
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: "30%", opacity: 0 }}
          transition={{ delay: 0.5, duration: 0.75 }}
        >
          <Link
            to="https://github.com/Code-Decoders"
            target={"_blank"}
            className="social-link"
          >
            GitHub
          </Link>
        </motion.div>
        <motion.div
          className="social-link"
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: "30%", opacity: 0 }}
          transition={{ delay: 0.5, duration: 0.75 }}
        >
          <Link
            to="https://www.youtube.com/channel/UC_NHhtPuz3ZynLGWWJXHrJQ"
            target={"_blank"}
            className="social-link"
          >
            YouTube
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default Social;
