import React from "react";
import "./footer.css";
import svg33 from "../../img/logo.png.svg";

const Footer = () => {
  return (
    <div className="footer">
      <img className="footer-logo" src={svg33} alt="ft-logo"/>
      <span className="footer-copyright">CodeDecoders © 2023</span>
    </div>
  );
};

export default Footer;
