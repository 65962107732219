import React from "react";
import "./careers.css";
import { motion } from "framer-motion";

const Careers = () => {
  return (
    <div className="careers">
      <motion.h1
        className="career-title"
        whileInView={{ y: 0, opacity: 1 }}
        initial={{ y: "30%", opacity: 0 }}
        transition={{ delay: 0.1, duration: 0.75 }}
      >
        Careers
      </motion.h1>
      <div className="careers-link">
        <ul className="careers-left-link">
          <motion.li
            whileInView={{ y: 0, opacity: 1 }}
            initial={{ y: "30%", opacity: 0 }}
            transition={{ delay: 0.1, duration: 0.75 }}
          >
            Project Manager
          </motion.li>
          <motion.li
            whileInView={{ y: 0, opacity: 1 }}
            initial={{ y: "30%", opacity: 0 }}
            transition={{ delay: 0.2, duration: 0.75 }}
          >
            FullStack Developer
          </motion.li>
          <motion.li
            whileInView={{ y: 0, opacity: 1 }}
            initial={{ y: "30%", opacity: 0 }}
            transition={{ delay: 0.3, duration: 0.75 }}
          >
            Unity Developer
          </motion.li>
          <motion.li
            whileInView={{ y: 0, opacity: 1 }}
            initial={{ y: "30%", opacity: 0 }}
            transition={{ delay: 0.4, duration: 0.75 }}
          >
            UI UX Designer
          </motion.li>
        </ul>

        <ul className="careers-right-link">
          <motion.li
            whileInView={{ y: 0, opacity: 1 }}
            initial={{ y: "30%", opacity: 0 }}
            transition={{ delay: 0.5, duration: 0.75 }}
          >
            Remote
          </motion.li>
          <motion.li
            whileInView={{ y: 0, opacity: 1 }}
            initial={{ y: "30%", opacity: 0 }}
            transition={{ delay: 0.6, duration: 0.75 }}
          >
            Remote
          </motion.li>
          <motion.li
            whileInView={{ y: 0, opacity: 1 }}
            initial={{ y: "30%", opacity: 0 }}
            transition={{ delay: 0.7, duration: 0.75 }}
          >
            Remote
          </motion.li>
          <motion.li
            whileInView={{ y: 0, opacity: 1 }}
            initial={{ y: "30%", opacity: 0 }}
            transition={{ delay: 0.8, duration: 0.75 }}
          >
            India
          </motion.li>
        </ul>
      </div>
    </div>
  );
};

export default Careers;
