import React from "react";
import "./about.css";
import { motion } from "framer-motion";

const About = () => {
  return (
    <div className="about">
      <motion.h1
        className="about-title"
        whileInView={{ y: 0, opacity: 1 }}
        initial={{ y: "20%", opacity: 0 }}
        transition={{ delay: 0.0, duration: 0.75 }}
      >
        About
      </motion.h1>
      <div className="about-container">
        <motion.div
          className="about-text-one"
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: "20%", opacity: 0 }}
          transition={{ delay: 0.1, duration: 0.75 }}
        >
          Our dynamic team is made up of passionate and skilled professionals
          who are committed to creating exceptional software solutions. With a
          blend of creativity and technical expertise, we pride ourselves on
          delivering innovative and user-centric products that exceed
          expectations.
        </motion.div>
        <motion.div
          className="about-text-2"
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: "20%", opacity: 0 }}
          transition={{ delay: 0.1, duration: 0.75 }}
        >
          We are focusing on intersecting cutting-edge technology and creative
          innovation, specializing in software development, app design, web
          development, blockchain solutions, and game development.
        </motion.div>

        <motion.div
          className="about-img-container"
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: "20%", opacity: 0 }}
          transition={{ delay: 0.2, duration: 0.75 }}
        >
          <img
            className="about-main-img"
            src="https://framerusercontent.com/images/fMtSKaIvXcf0tLCB3dZ7LSMNT4.jpg?scale-down-to=1024"
            alt="At-img"
          />
          <motion.div
            className="about-second-img"
            whileInView={{ y: 0, opacity: 1 }}
            initial={{ y: "20%", opacity: 0 }}
            transition={{ delay: 0.2, duration: 0.75 }}
          >
            <img
              className="about-img-1"
              src="https://framerusercontent.com/images/4U4wvZ6Y4cr6PSr7vqTNUokAOQ.jpg?scale-down-to=1024"
              alt="At-img2"
            />
            <img
              className="about-img-2"
              src="https://framerusercontent.com/images/VxEqXpiBjpU0IwtBN2bIk9EfYM.jpg?scale-down-to=1024"
              alt="At-img3"
            />
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default About;
