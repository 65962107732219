import React from "react";
import "./clients.css";
import svg from "../../img/clients.png1.svg";
import svg22 from "../../img/download (1).svg";
import svg2 from "../../img/download (2).svg";
import svg3 from "../../img/download (3).svg";
import svg4 from "../../img/download (4).svg";
import svg5 from "../../img/download (5).svg";
import polygon from "../../img/polygon.svg";
import tezos from "../../img/tezos.svg";
import consensys from "../../img/consensys.svg";
import filecoin from "../../img/filecoin.png";
import connext from "../../img/connext.png";
import safe from "../../img/safe.png";
import { motion } from "framer-motion";

const Clients = () => {
  return (
    <div className="clients">
      <motion.h1
        className="client-title"
        whileInView={{ y: 0, opacity: 1 }}
        initial={{ y: "30%", opacity: 0 }}
        transition={{ delay: 0.1, duration: 0.75 }}
      >
        Partners
      </motion.h1>

      <motion.div
        className="clients-logos"
        whileInView={{ y: 0, opacity: 1 }}
        initial={{ y: "30%", opacity: 0 }}
        transition={{ delay: 0.1, duration: 0.75 }}
      >
        <motion.div
          className="client-logo"
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: "30%", opacity: 0 }}
          transition={{ delay: 0.2, duration: 0.75 }}
        >
          <img
            className="client-logo-img"
            src={polygon}
            alt="logos"
          />
        </motion.div>
        <motion.div
          className="client-logo"
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: "30%", opacity: 0 }}
          transition={{ delay: 0.3, duration: 0.75 }}
        >
          <img
            className="client-logo-img"
            src={tezos}
            alt="logos1"
          />
        </motion.div>
        <motion.div
          className="client-logo"
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: "30%", opacity: 0 }}
          transition={{ delay: 0.4, duration: 0.75 }}
        >
          <img
            className="client-logo-img"
            src={consensys}
            alt="logos2"
          />
        </motion.div>
        <motion.div
          className="client-logo"
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: "30%", opacity: 0 }}
          transition={{ delay: 0.5, duration: 0.75 }}
        >
          <img
            className="client-logo-img"
            src={filecoin}
            alt="logos3"
          />
        </motion.div>
        <motion.div
          className="client-logo"
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: "30%", opacity: 0 }}
          transition={{ delay: 0.6, duration: 0.75 }}
        >
          <img
            className="client-logo-img"
            src={connext}
            alt="logos4"
          />
        </motion.div>
        <motion.div
          className="client-logo"
          whileInView={{ y: 0, opacity: 1 }}
          initial={{ y: "30%", opacity: 0 }}
          transition={{ delay: 0.7, duration: 0.75 }}
        >
          <img
            className="client-logo-img"
            src={safe}
            alt="logos5"
          />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Clients;
