import React from "react";
import "./mainComponents.css";
import svgWd from "../../img/logo.png.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { motion } from "framer-motion";
import { useAllProjects } from "../../hooks";
import { formatTitle } from "../../utils/formatTitle";

const Maincomponents = () => {
  const { pathname } = useLocation();

  const [projects, isLoading, error] = useAllProjects();

  useEffect(() => {
    console.log(projects);
  }, [projects]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="maincomponents">
      <motion.div
        className="agency-text"
        whileInView={{ y: 0, opacity: 1 }}
        initial={{ y: "30%", opacity: 0 }}
        transition={{ delay: 0.1, duration: 0.77 }}
      >
        <h1 className="agency-title">
          Code <br />
          Decoders
        </h1>
        <p className="agency-para">
          We are an award-winning software agency that specializes in crafting
          cutting-edge solutions for a variety of industries.
          <br />
          <br />
          Our expertise spans across a wide range of areas, including Full Stack
          development, Blockchain Technology, and Game Development.
        </p>
      </motion.div>

      <div
        className="main-container"
        animate={{ y: 0, opacity: 1 }}
        initial={{ y: "10%", opacity: 0 }}
        transition={{ delay: 0.1, duration: 0.77 }}
      >
        {projects.map((project) => (
          <Link className="container" to={"/project/" + project.name}>
            <div className="img-container">
              <img className="img" src={project.youtubeThumbnail} alt="img1" />
            </div>

            <div className="text-container">
              <h3 className="img-title">{formatTitle(project.name)}</h3>
              <div className="img-text">
                {project.category.slice(0, 3).map((category, index) => (
                  <>
                    <h3 className="title">{formatTitle(category)}</h3>
                    {index !== project.category.slice(0, 3).length - 1 && (
                      <img className="img-logo" src={svgWd} alt="Wdlogo" />
                    )}
                  </>
                ))}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Maincomponents;
